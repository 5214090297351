<template>
  <li>
    <div :class="{ bold: isFolder }" @click="toggle" @dblclick="changeType">
      <!-- <span v-if="isFolder">
        <i :class="!open ?'mdi mdi-chevron-right':'mdi mdi-chevron-down'"></i>
      </span>-->
      <div class="media">
        <div
          :style="[
            model.is_read
              ? { 'background-color': 'DodgerBlue' }
              : { 'background-color': 'Tomato' },
          ]"
        >
          <img
            class="d-flex mr-3"
            src="@/assets/img/logo.png"
            width="60"
            alt="Generic placeholder image"
          />
        </div>

        <div class="media-body">
          <span>
            <strong class="col-md-2">Unit Kerja:</strong>
            {{ model.position }}
          </span>
          <br />
          <span>
            <strong class="col-md-2">Jabatan:</strong>
            {{ model.jabatan }}
          </span>
          <br />
          <span>
            <strong class="col-md-2">Status:</strong>
            {{ model.status }}
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
              model.status1
            }}
          </span>
          <br />
          <!-- <span>
            {{ model.status1 }}
          </span>
          <br /> -->
          <span>
            <strong class="col-md-2">Profil : </strong>
            {{ model.profil }}
          </span>
          <br />
          <!-- <span>
            <strong class="col-md-2">Id Dispo : </strong>
            {{ model.id_dispo }}
          </span>
          <br /> -->
          <span>
            <strong class="col-md-2">Catatan:</strong>
            <span v-html="model.description"></span>
          </span>
        </div>
        <span v-if="isFolder" class="pull-right">
          <i
            :class="!open ? 'mdi mdi-chevron-right' : 'mdi mdi-chevron-down'"
          ></i>
        </span>
      </div>
    </div>
    <ul class="tree-view" v-show="open" v-if="isFolder">
      <li v-for="(model, index) in model.children" :key="index" :model="model">
        <div class="media">
          <img
            class="d-flex mr-3"
            src="@/assets/img/logo.png"
            width="60"
            alt="Generic placeholder image"
          />
          <div class="media-body">
            <h5 class="mt-0 mb-1">{{ model.name }}</h5>
            <span>
              <strong class="col-md-2">Posisi:</strong>
              {{ model.position }}
            </span>
            <br />
            <span>
              <strong class="col-md-2">Status:</strong>
              {{ model.status }}
            </span>
            <br />
            <span>
              <strong class="col-md-2">Catatan:</strong>
              {{ model.description }}
            </span>
          </div>
        </div>
      </li>
    </ul>
  </li>
</template>
<script>
import Vue from "vue";
export default {
  components: {},
  props: {
    model: Object,
  },
  data() {
    return {
      open: true,
    };
  },
  computed: {
    isFolder() {
      return this.model.children && this.model.children.length;
    },
  },
  methods: {
    toggle() {
      if (this.isFolder) {
        this.open = !this.open;
      }
    },
    // onKomentar() {
    //   let route = this.$router.resolve(`/dispositionSend/komentar/${this.model.id_dispo}`);
    //   window.open(route.href, '_blank');
    // },
    changeType() {
      if (!this.isFolder) {
        Vue.set(this.model, "children", []);
        this.addChild();
        this.open = true;
      }
    },
  },
};
</script>