<template>
  <li>
    <div :class="{ bold: isFolder }" @click="toggle" @dblclick="changeType">
      <!-- <span v-if="isFolder">
        <i :class="!open ?'mdi mdi-chevron-right':'mdi mdi-chevron-down'"></i>
      </span>-->
      <div class="media">
        <div
          :style="[
            model.is_read
              ? { 'background-color': 'DodgerBlue' }
              : { 'background-color': 'Tomato' },
          ]"
        >
          <img
            class="d-flex mr-3"
            src="@/assets/img/logo.png"
            width="60"
            alt="Generic placeholder image"
          />
        </div>

        <div class="media-body">
          <span>
            <strong class="col-md-2">Unit Kerja:</strong>
            {{ model.position }}
          </span>
          <br />
          <span>
            <strong class="col-md-2">Jabatan:</strong>
            {{ model.jabatan }}
          </span>
          <br />
          <span>
            <strong class="col-md-2">Status:</strong>
            {{ model.status }}
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
              model.status1
            }}
          </span>
          <br />
          <!-- <span>
            {{ model.status1 }}
          </span>
          <br /> -->
          <span>
            <strong class="col-md-2">Profil : </strong>
            {{ model.profil }}
          </span>
          <br />
          <!-- <span>
            <strong class="col-md-2">Id Dispo : </strong>
            {{ model.id_dispo }}
          </span> -->
          <span>
            <strong class="col-md-2">Catatan:</strong>
            <span v-html="model.description"></span>
          </span>
        </div>

        <span v-if="isFolder" class="pull-right">
          <i
            :class="!open ? 'mdi mdi-chevron-right' : 'mdi mdi-chevron-down'"
          ></i>
        </span>
        <div>
          <!-- <a href="">Komentar</a>&nbsp;
          <a href="">Close Komentar</a> -->
          <!-- <button>Close Komentar</button> -->
          <!-- <button @click="onKomentar()" class="btn btn-sm btn-success" style="margin-right:10px;">Komentar</button> -->
          <!-- <button @click="onCloseKomentar()" class="btn btn-sm btn-danger">Close Komentar</button> -->
          <!-- <button type="button" @click="showModal({type:'close'})" class="btn btn-danger" title="Kembali">Close Komentar</button> -->
        </div>
      </div>
      <ModalDefault
        v-show="isVisible"
        :title="modalTitle"
        :content="modalContent"
        :action="ActionChangeable"
        :confirmation="isConfirmation"
        @close="onClose"
      />
    </div>
    <ul class="tree-view" v-show="open" v-if="isFolder">
      <li>
        <subItem
          v-for="(model, index) in model.children"
          :key="index"
          :model="model"
        ></subItem>
      </li>
    </ul>
  </li>
</template>
<script>
import Vue from "vue";
import subItem from "./subItem";
export default {
  components: {
    subItem,
  },
  props: {
    model: Object,
  },
  data() {
    return {
      open: true,
      isVisible: false,
      modalTitle: "",
      modalContent: "",
      isModalVisible: false,
      isConfirmation: false,
      isInformation: false,
      isTouched: false,
      isDisabled: false,
      ActionChangeable: null,
    };
  },
  computed: {
    isFolder() {
      return this.model.children && this.model.children.length;
    },
  },
  methods: {
    toggle() {
      if (this.isFolder) {
        this.open = !this.open;
      }
    },
    // onKomentar(){
    //   // const link = document.createElement('a')
    //   // let unit_id = JSON.parse(localStorage.getItem('user')).unit_id
    //   // // link.href = `${this.baseUrl}/${val}/export-excel?start_date=${this.start_date}&end_date=${this.end_date}&unit_id=${unit_id}`
    //   // link.href = `${val}`
    //   // link.target = "_blank";
    //   // link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
    //   // let routeData = this.$router.resolve({name: 'routeName', query: {data: "someData"}});
    //   // window.open(routeData.href, '_blank');
    //   // let route = this.$router.resolve({path: 'dispositionSend/komentar/'});
    //   let route = this.$router.resolve(`/dispositionSend/komentar/${this.model.id_dispo}`); // This also works.
    //   window.open(route.href, '_blank');
    // },
    // onCloseKomentar() {
    //   let route = this.$router.resolve(`/dispositionSend/komentar/${this.model.id_dispo}`); // This also works.
    //   window.open(route.href, '_blank');
    // },
    onClose() {
      this.isVisible = false;
    },
    showModal(option) {
      this.isVisible = true;
      if (option.type == "close") {
        // this.modalTitle = "Kembali?";
        this.modalContent =
          "<h2>APAKAH ANDA YAKIN UNTUK MENUTUP DISPOSISI INI?</h2><br><p>AKSI INI TIDAK DAPAT DIBATALKAN, PENERIMA DAN PENGIRIM DISPOSISI TIDAK DAPAT MELANJUTKAN AKSI KOMENTAR</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack;
      }
    },
    goBack() {
      // this.$store.dispatch("dispositionSend/onCancel");
      this.$store.dispatch(
        "dispositionSend/onCloseDisposisi",
        this.model.id_dispo
      );
      // this.isVisible = false;
      // let route = this.$router.resolve(`/dispositionSend/komentar/${this.model.id_dispo}`); // This also works.
      // window.open(route.href, '_blank');
    },
    changeType() {
      if (!this.isFolder) {
        Vue.set(this.model, "children", []);
        this.addChild();
        this.open = true;
      }
    },
  },
};
</script>